import { useState, useEffect } from "react";
import {
  Box,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  Grid,
  Text,
  Select,
  Image,
  Divider,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import {
  collection,
  addDoc,
  serverTimestamp,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db, storage } from "config/firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import styled from "styled-components";
import SimpleMDEEditor from "react-simplemde-editor";
import {
  GoogleMap,
  Marker,
  useLoadScript,
  useJsApiLoader,
} from "@react-google-maps/api";
import custom from "styles/themes/custom";

const MapContainerStyle = {
  width: "100%",
  height: "400px",
};

const center = {
  lat: 36.13817904641932,
  lng: -5.846385661096199,
};

const Error = styled.div`
  color: red;
  font-size: 0.8rem;
  margin-bottom: 0.25rem;
`;

function RutaForm({ initialValues, isEditing = false }) {
  const [imageUrl, setImageUrl] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (initialValues && initialValues.image) {
      setImageUrl(initialValues.image);
    }
  }, [initialValues]);

  const validationSchema = Yup.object()
    .shape({
      name: Yup.string().required("El título es requerido"),
      position: Yup.object().shape({
        lat: Yup.number().required("La latitud es requerida"),
        lng: Yup.number().required("La longitud es requerida"),
      }),
      content: Yup.object().shape({
        es: Yup.string(),
        en: Yup.string(),
      }),
      sheet: Yup.string(),
      brouchure: Yup.string(),

      image: Yup.mixed(),
      imageURL: Yup.string(),
      url: Yup.string(),
    })
    .test("imageURL", "La imagen es requerida", (value, { parent }) => {
      if (!value && !parent.imageURL) {
        return false;
      }
      return true;
    });

  const handleSubmit = async (values, actions) => {
    try {
      let newImageURL = "";

      if (values.image) {
        const storageRef = ref(storage, `images/${uuidv4()}`);
        await uploadBytes(storageRef, values.image[0]);
        newImageURL = await getDownloadURL(storageRef);
      } else {
        newImageURL = values.imageURL;
      }

      const postData = {
        name: values.name || "",
        nameLowerCase: values.name?.toLowerCase() || "",
        content: {
          es: values.content?.es || "",
          en: values.content?.en || "",
        },
        image: newImageURL || "",
        position: values.position,
        sheet: values.sheet || "",
        brouchure: values.brouchure || "",
        wikiloc: values.wikiloc || "",
        url: values.url || "",
        createdAt: isEditing ? values.createdAt : serverTimestamp(),
      };
      console.log("postData", postData);

      if (isEditing) {
        const postRef = doc(db, "rutas", values.id);
        await updateDoc(postRef, postData);
      } else {
        await addDoc(collection(db, "rutas"), postData);
      }

      navigate("/rutas");
    } catch (error) {
      console.log(error);
      actions.setStatus(error.message);
      actions.setSubmitting(false);
    }
  };

  const handleMapClick = (event, setFieldValue) => {
    const { latLng } = event;
    const lat = latLng.lat();
    const lng = latLng.lng();
    const newPosition = { lat, lng };
    setFieldValue("position", newPosition);
  };

  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBosIeQohGgehd7ST8Wi-2Q2bAF1O7bGDE",
  });
  // const { isLoaded, loadError } = useLoadScript({
  //   id: "google-map-script",
  //   googleMapsApiKey: "AIzaSyBosIeQohGgehd7ST8Wi-2Q2bAF1O7bGDE",
  // });

  const [scaledSize, setScaledSize] = useState(null);

  useEffect(() => {
    if (typeof window !== "undefined" && isLoaded) {
      const size = new window.google.maps.Size(40, 53.18);
      setScaledSize(size);
    }
  }, [isLoaded]);

  const renderIcon = (type) => {
    const iconUrls = {
      Monumentos:
        "https://firebasestorage.googleapis.com/v0/b/turismozahara.appspot.com/o/icons%2Fmonument.png?alt=media&token=d6c46a48-f951-4b5c-bcf2-ae8afa230cbf&_gl=1*1chcfva*_ga*MTU1MDA0NTMyMi4xNjcyOTYzMTQw*_ga_CW55HF8NVT*MTY4NjA1OTYyMy4yMi4xLjE2ODYwNjAyOTkuMC4wLjA.",
      "Centros del Ayuntamiento":
        "https://firebasestorage.googleapis.com/v0/b/turismozahara.appspot.com/o/icons%2Fhall.png?alt=media&token=8fe904a0-e7e4-4192-8511-35a278fc3185&_gl=1*1gvsqr7*_ga*MTU1MDA0NTMyMi4xNjcyOTYzMTQw*_ga_CW55HF8NVT*MTY4NjA2MjgxMC4yMy4xLjE2ODYwNjI4MTcuMC4wLjA.",
      Playas: "https://maps.google.com/mapfiles/ms/icons/red-dot.png",
      Hoteles:
        "https://firebasestorage.googleapis.com/v0/b/turismozahara.appspot.com/o/icons%2Fhotel.png?alt=media&token=8b56a85e-ad7f-41dd-8a1a-1b10c708f711&_gl=1*1fzzoo3*_ga*MTU1MDA0NTMyMi4xNjcyOTYzMTQw*_ga_CW55HF8NVT*MTY4NjA1OTYyMy4yMi4xLjE2ODYwNjAxOTIuMC4wLjA.",
      Restaurantes: "https://maps.google.com/mapfiles/ms/icons/red-dot.png",
      Cajeros:
        "https://firebasestorage.googleapis.com/v0/b/turismozahara.appspot.com/o/icons%2Fatm.png?alt=media&token=35f7dec4-e496-4c4d-b157-8fd3d6046253&_gl=1*1rin9wj*_ga*MTU1MDA0NTMyMi4xNjcyOTYzMTQw*_ga_CW55HF8NVT*MTY4NjA2Njc4Ni4yNS4xLjE2ODYwNjc0MjcuMC4wLjA.",
      Aparcamientos:
        "https://firebasestorage.googleapis.com/v0/b/turismozahara.appspot.com/o/icons%2Fparking.png?alt=media&token=81718208-0bf4-41e2-a838-453d4bd21765&_gl=1*10r8l9f*_ga*MTU1MDA0NTMyMi4xNjcyOTYzMTQw*_ga_CW55HF8NVT*MTY4NjA3MDQ5Ni4yNi4xLjE2ODYwNzA5MTYuMC4wLjA.",
      "Centros de Salud":
        "https://firebasestorage.googleapis.com/v0/b/turismozahara.appspot.com/o/icons%2Fhelth.png?alt=media&token=d922132e-884b-4a24-b6d0-45ce54200d29&_gl=1*1cgy91t*_ga*MTU1MDA0NTMyMi4xNjcyOTYzMTQw*_ga_CW55HF8NVT*MTY4NjA3MDQ5Ni4yNi4xLjE2ODYwNzA4MDEuMC4wLjA.",
      Bus: "https://firebasestorage.googleapis.com/v0/b/turismozahara.appspot.com/o/icons%2Fbus.png?alt=media&token=cf6650a3-6082-43ee-91b4-fec12a7b6cdd&_gl=1*1vs0nay*_ga*MTU1MDA0NTMyMi4xNjcyOTYzMTQw*_ga_CW55HF8NVT*MTY4NjA3MDQ5Ni4yNi4xLjE2ODYwNzA2OTcuMC4wLjA.",
      Taxi: "https://firebasestorage.googleapis.com/v0/b/turismozahara.appspot.com/o/icons%2Ftaxi.png?alt=media&token=9e5dfb52-b686-4907-8eec-c6944c77f49c&_gl=1*1b5dk9x*_ga*MTU1MDA0NTMyMi4xNjcyOTYzMTQw*_ga_CW55HF8NVT*MTY4NjA3MDQ5Ni4yNi4xLjE2ODYwNzA3MTQuMC4wLjA.",
      Bibliotecas:
        "https://firebasestorage.googleapis.com/v0/b/turismozahara.appspot.com/o/icons%2Fbook.png?alt=media&token=62c6155f-2793-4f42-ad56-e408eb08df7a&_gl=1*jklv5q*_ga*MTU1MDA0NTMyMi4xNjcyOTYzMTQw*_ga_CW55HF8NVT*MTY4NjY3NTQ3Ni4zNy4xLjE2ODY2NzU4NTMuMC4wLjA.",
      "Oficinas de Turismo":
        "https://firebasestorage.googleapis.com/v0/b/turismozahara.appspot.com/o/icons%2Finfo.png?alt=media&token=90ec7ced-4785-4a8e-843c-cb14661d436e&_gl=1*1wxzv3g*_ga*MTU1MDA0NTMyMi4xNjcyOTYzMTQw*_ga_CW55HF8NVT*MTY4NjA3MDQ5Ni4yNi4xLjE2ODYwNzA1MDAuMC4wLjA.",
    };

    return {
      url: iconUrls[type],
      scaledSize: scaledSize,
    };
  };

  if (loadError) {
    return <div>Error loading Google Maps</div>;
  }

  const handleDrop = (event, form) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      form.setFieldValue("image", [file]);
      setImageUrl(URL.createObjectURL(file));
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  return (
    <Box>
      <Heading>{isEditing ? "Editar marker" : "Nuevo marker"}</Heading>
      <Formik
        initialValues={
          initialValues || {
            title: "",
            content: { es: "", en: "" },
            position: { lat: "", lng: "" },
            type: "",
            url: "",
            sheet: "",
            brochure: "",
            wikiloc: "",
            image: "",
          }
        }
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({ values, status, setFieldValue, isSubmitting, errors }) => (
          <Form>
            {console.log(values)}
            {console.log(errors)}
            <Field name="name">
              {({ field }) => (
                <FormControl>
                  <FormLabel>Título</FormLabel>
                  <Input {...field} />
                </FormControl>
              )}
            </Field>
            <ErrorMessage
              name={"name"}
              render={(msg) => (
                <Text color="red.400" fontSize="sm">
                  {msg}
                </Text>
              )}
            />
            <Field name="content.es">
              {({ field }) => (
                <FormControl>
                  <FormLabel>Contenido (es)</FormLabel>
                  <SimpleMDEEditor
                    value={field.value}
                    onChange={(value) => setFieldValue("content.es", value)} // Actualiza el valor usando setFieldValue
                  />
                </FormControl>
              )}
            </Field>
            <Field name="content.en">
              {({ field }) => (
                <FormControl>
                  <FormLabel>Contenido (en)</FormLabel>
                  <SimpleMDEEditor
                    value={field.value}
                    onChange={(value) => setFieldValue("content.en", value)} // Actualiza el valor usando setFieldValue
                  />
                </FormControl>
              )}
            </Field>
            <Field name="url">
              {({ field }) => (
                <FormControl>
                  <FormLabel>Enlace</FormLabel>
                  <Input {...field} />
                </FormControl>
              )}
            </Field>
            <Field name="sheet">
              {({ field }) => (
                <FormControl>
                  <FormLabel>Ficha</FormLabel>
                  <Input {...field} />
                </FormControl>
              )}
            </Field>
            <Field name="brochure">
              {({ field }) => (
                <FormControl>
                  <FormLabel>Folleto</FormLabel>
                  <Input {...field} />
                </FormControl>
              )}
            </Field>
            <Field name="wikiloc">
              {({ field }) => (
                <FormControl>
                  <FormLabel>Wikiloc</FormLabel>
                  <Input {...field} />
                </FormControl>
              )}
            </Field>
            <FormControl>
              <FormLabel>Imagen</FormLabel>
              <Field name="image">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={form.errors.image && form.touched.image}
                    onDrop={(event) => handleDrop(event, form)}
                    onDragOver={handleDragOver}
                  >
                    <Input
                      type="file"
                      accept="image/*"
                      onChange={(event) => {
                        const file = event.currentTarget.files[0];
                        if (file) {
                          form.setFieldValue("image", [file]);
                          setImageUrl(URL.createObjectURL(file));
                        }
                      }}
                    />
                    <ErrorMessage name="image" component={Text} />
                  </FormControl>
                )}
              </Field>

              {imageUrl && (
                <Box>
                  <Image src={imageUrl} alt="Preview" w={"100px"} />
                </Box>
              )}
            </FormControl>
            <Divider padding="10px" />

            <Field name="position.lat">
              {({ field }) => (
                <FormControl>
                  <FormLabel>Latitud</FormLabel>
                  <Input {...field} />
                </FormControl>
              )}
            </Field>
            <ErrorMessage
              name={"position.lat"}
              render={(msg) => (
                <Text color="red.400" fontSize="sm">
                  {msg}
                </Text>
              )}
            />
            <Field name="position.lng">
              {({ field }) => (
                <FormControl>
                  <FormLabel>Longitud</FormLabel>
                  <Input {...field} />
                </FormControl>
              )}
            </Field>
            <ErrorMessage
              name={"position.lng"}
              render={(msg) => (
                <Text color="red.400" fontSize="sm">
                  {msg}
                </Text>
              )}
            />
            <Divider padding="10px" />
            {isLoaded && (
              <GoogleMap
                mapContainerStyle={MapContainerStyle}
                center={center}
                zoom={16}
                onClick={(event) => handleMapClick(event, setFieldValue)}
                // icon={renderIcon(values.type)}
                options={{
                  disableDefaultUI: true,
                  clickableIcons: false,
                  mapTypeId: "satellite",
                }}
              >
                <>
                  {" "}
                  <Marker
                    position={values.position}
                    // position={center}
                    // icon={renderIcon(values.type)}
                    draggable={true}
                    onDragEnd={(event) => handleMapClick(event, setFieldValue)}
                  />
                </>
              </GoogleMap>
            )}
            <Button
              mt={4}
              colorScheme="teal"
              isLoading={isSubmitting}
              type="submit"
            >
              {isEditing ? "Actualizar" : "Crear"}
            </Button>
            {status && <Text color="red">{status}</Text>}
          </Form>
        )}
      </Formik>
    </Box>
  );
}

export default RutaForm;
