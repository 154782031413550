import { useState, useEffect } from "react";

const keysOf = (o) => Object.keys(o);
export const sizes = {
  mobile: "450px",
  tablet: "769px",
  desktop: "1280px",
};
const minWidthQuery = (width) => `@media (min-width: ${width})`;
export const from = keysOf(sizes).reduce(
  (acc, key) =>
    Object.assign(Object.assign({}, acc), { [key]: minWidthQuery(sizes[key]) }),
  {}
);

const maxWidthQuery = (width) => `@media (max-width: ${width})`;
export const until = keysOf(sizes).reduce(
  (acc, key) =>
    Object.assign(Object.assign({}, acc), { [key]: maxWidthQuery(sizes[key]) }),
  {}
);

export const useMediaQuery = (query) => {
  const mediaQuery = query.replace("@media", "").trim();
  const [match, setMatch] = useState(false);
  useEffect(() => {
    const mediaQueryList = window.matchMedia(mediaQuery);
    const handleChange = (event) => setMatch(event.matches);
    mediaQueryList.addListener(handleChange);
    return () => mediaQueryList.removeListener(handleChange);
  }, [mediaQuery]);

  useEffect(() => {
    setMatch(() => window.matchMedia(mediaQuery).matches);
  }, []);

  return match;
};
