import React, { useState } from "react";
import {
  IconButton,
  Avatar,
  Box,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Link,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  CloseButton,
  useColorMode,
} from "@chakra-ui/react";
import { FiMenu, FiChevronDown, FiLogOut } from "react-icons/fi";
import useAuth from "hooks/useAuthentication";
import Sidebar from "components/Sidebar";
import routes from "constants/routes";
import { SunIcon, MoonIcon } from "@chakra-ui/icons";
import { signOut } from "firebase/auth";

export default function SidebarWithHeader({ portalId = 1, children }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box minH="100vh" bg={useColorModeValue("gray.50", "gray.900")}>
      <SidebarContent
        onClose={onClose}
        display={{ base: "none", md: "block" }}
        portalId={portalId}
      />

      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <>
            <CloseButton onClick={onClose} />
            <SidebarContent onClose={onClose} portalId={portalId} />
          </>
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p="4">
        {children}
      </Box>
    </Box>
  );
}

const SidebarContent = ({ portalId, onClose, ...rest }) => {
  const [sidebarVariant, setSidebarVariant] = useState("transparent");
  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue("gray.50", "gray.900")}
      w={{ base: "full", md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Sidebar
        routes={routes}
        logoText={"Turismo Zahara"}
        display="none"
        sidebarVariant={sidebarVariant}
        portalId={portalId}
        onClose={onClose}
        // {...rest}
      />
    </Box>
  );
};

const NavItem = ({ icon, children, link, ...rest }) => {
  return (
    <Link
      href={link}
      style={{ textDecoration: "none" }}
      _focus={{ boxShadow: "none" }}
    >
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        fontWeight={500}
        _hover={{
          bg: "rgba(255, 255, 255,0.25)",
          // color: "white",
          boxShadow: "lg",
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={
              {
                // color: "white",
              }
            }
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};

const MobileNav = ({ onOpen, ...rest }) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const { logout, user } = useAuth();

  const handleLogout = () => {
    logout();
  };

  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue("gray.100", "gray.900")} //este
      justifyContent="space-between"
      {...rest}
    >
      <Text
        display={{ base: "flex", md: "none" }}
        fontSize="2xl"
        fontFamily="monospace"
        fontWeight="bold"
      >
        Turismo Zahara
      </Text>
      <Flex alignItems="center" justifyContent={"space-between"} w="100%">
        {/* <Autocomplete /> */}
        <HStack spacing={{ base: "0", md: "6" }}>
          <Flex alignItems={"center"}></Flex>
        </HStack>
        <Flex>
          {/* <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: "none" }}
            >
              <HStack>
                <Avatar size={"sm"} name={user?.displayName} />
                <VStack
                  display={{ base: "none", md: "flex" }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2"
                >
                  <Text fontSize="sm">{user?.email}</Text>
                  <Text fontSize="xs" color="gray.600">
                    Admin
                  </Text>
                </VStack>
                <Box display={{ base: "none", md: "flex" }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue("white", "gray.900")}
              borderColor={useColorModeValue("gray.200", "gray.700")}
            >
              <MenuItem onClick={logout}>Salir</MenuItem>
            </MenuList>
          </Menu> */}
          <Box>
            <IconButton
              aria-label="Toggle color mode"
              variant="ghost"
              icon={colorMode === "light" ? <MoonIcon /> : <SunIcon />}
              onClick={toggleColorMode}
            />
          </Box>

          <IconButton
            // display={{ base: "flex", md: "none" }}
            onClick={handleLogout}
            variant="ghost"
            aria-label="Logout"
            icon={<FiLogOut />}
          />
          <IconButton
            display={{ base: "flex", md: "none" }}
            onClick={onOpen}
            variant="outline"
            aria-label="open menu"
            icon={<FiMenu />}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
