import React from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { Box } from "@chakra-ui/react";
import styled from "@emotion/styled";
import "easymde/dist/easymde.min.css";
const MarkdownBox = styled(Box)`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 600;
  }

  h1 {
    font-size: 2.3rem;

    margin-bottom: 0.5rem;
  }

  h2 {
    font-size: 2rem;

    margin-bottom: 0.5rem;
  }

  h3 {
    font-size: 1.7rem;

    margin-bottom: 0.5rem;
  }
  h4 {
    font-size: 1.4rem;
    /* margin-top: 1.5rem; */
    margin-bottom: 0.5rem;
  }

  ul {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  ol {
    margin-left: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  li {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  p {
    margin-bottom: 1rem;
  }
  img {
    width: 100%;
    height: auto;
    /* max-width: 250px; */
  }

  /* Agrega estilos adicionales para otros elementos aquí */
`;

const MarkdownRenderer = ({ markdownContent }) => {
  return (
    <MarkdownBox>
      <ReactMarkdown rehypePlugins={[rehypeRaw]}>
        {markdownContent}
      </ReactMarkdown>
    </MarkdownBox>
  );
};

export default MarkdownRenderer;
