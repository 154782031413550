// firebaseService.js
import {
  getFirestore,
  collection,
  addDoc,
  doc,
  setDoc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { app } from "config/firebase";
const db = getFirestore(app);
const storage = getStorage(app);

export const deleteOperario = async (userId) => {
  try {
    const userRef = doc(db, "operarikos", userId);
    await deleteDoc(userRef);
    console.log("Usuario eliminado con éxito");
  } catch (error) {
    console.error("Error al eliminar el usuario:", error);
  }
};

export const desactiveOperario = async (userId) => {
  try {
    const userRef = doc(db, "operarios", userId);
    await updateDoc(userRef, {
      isActive: false,
    });
  } catch (error) {
    console.error("Error al desactivar el usuario:", error);
  }
};
