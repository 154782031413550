// components/SignUp.js
import { Box } from "@chakra-ui/react";

import Sidebar from "components/layouts/Sidebar";
import MarkerForm from "components/Form/MarkerForm";

const AddMarker = () => {
  return (
    <Sidebar>
      <Box>
        <MarkerForm />
      </Box>
    </Sidebar>
  );
};

export default AddMarker;
