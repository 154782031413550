import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "containers/Guest/Login";
import Home from "containers/Main/Home";
import Error404 from "containers/Guest/Error404";
import ProtectedRoute from "containers/ProtectedRoute";
import AlojamientosList from "containers/Main/Alojamientos";
import AddAlojamiento from "containers/Main/Alojamientos/Add";
import EditAlojamiento from "containers/Main/Alojamientos/Edit";
import AlojamientoDetail from "containers/Main/Alojamientos/Details";
import RutasList from "containers/Main/Rutas";
import AddRuta from "containers/Main/Rutas/Add";
import EditRuta from "containers/Main/Rutas/Edit";
import RutaDetail from "containers/Main/Rutas/Details";
import RestaurantesList from "containers/Main/Restaurantes";
import AddRestaurante from "containers/Main/Restaurantes/Add";
import EditRestaurante from "containers/Main/Restaurantes/Edit";
import RestauranteDetail from "containers/Main/Restaurantes/Details";
import AgendaList from "containers/Main/Agenda";
import AddAgenda from "containers/Main/Agenda/Add";
import EditAgenda from "containers/Main/Agenda/Edit";
import AgendaDetail from "containers/Main/Agenda/Details";
import AddPost from "containers/Main/Posts/Add";
import PostList from "containers/Main/Posts";
import EditPost from "containers/Main/Posts/Edit";
import PostDetails from "containers/Main/Posts/Details";
import MarkerList from "containers/Main/Markers";
import AddMarker from "containers/Main/Markers/Add";
import EditMarker from "containers/Main/Markers/Edit";
import MarkerDetail from "containers/Main/Markers/Details";
import MediaList from "containers/Main/Media";
import AddMedia from "containers/Main/Media/Add";
import EditMedia from "containers/Main/Media/Edit";
import MediaDetail from "containers/Main/Media/Details";

const Root = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route
        index
        path="/home"
        element={
          <ProtectedRoute>
            <Home />
          </ProtectedRoute>
        }
      />

      {/* Alojamientos */}
      <Route path="/alojamientos">
        <Route
          index
          path="/alojamientos"
          element={
            <ProtectedRoute>
              <AlojamientosList />
            </ProtectedRoute>
          }
        />
        <Route
          path="add"
          element={
            <ProtectedRoute>
              <AddAlojamiento />
            </ProtectedRoute>
          }
        />
        <Route
          path=":id/edit"
          element={
            <ProtectedRoute>
              <EditAlojamiento />
            </ProtectedRoute>
          }
        />
        <Route
          path=":id/view"
          element={
            <ProtectedRoute>
              <AlojamientoDetail />
            </ProtectedRoute>
          }
        />
      </Route>
      {/* Agenda */}
      <Route path="/agenda">
        <Route
          index
          path="/agenda"
          element={
            <ProtectedRoute>
              <AgendaList />
            </ProtectedRoute>
          }
        />
        <Route
          path="add"
          element={
            <ProtectedRoute>
              <AddAgenda />
            </ProtectedRoute>
          }
        />
        <Route
          path=":id/edit"
          element={
            <ProtectedRoute>
              <EditAgenda />
            </ProtectedRoute>
          }
        />
        <Route
          path=":id/view"
          element={
            <ProtectedRoute>
              <AgendaDetail />
            </ProtectedRoute>
          }
        />
      </Route>
      {/* rutas */}
      <Route path="/rutas">
        <Route
          index
          path="/rutas"
          element={
            <ProtectedRoute>
              <RutasList />
            </ProtectedRoute>
          }
        />
        <Route
          path="add"
          element={
            <ProtectedRoute>
              <AddRuta />
            </ProtectedRoute>
          }
        />
        <Route
          path=":id/edit"
          element={
            <ProtectedRoute>
              <EditRuta />
            </ProtectedRoute>
          }
        />
        <Route
          path=":id/view"
          element={
            <ProtectedRoute>
              <RutaDetail />
            </ProtectedRoute>
          }
        />
      </Route>
      {/* markers */}
      <Route path="/markers">
        <Route
          index
          path="/markers"
          element={
            <ProtectedRoute>
              <MarkerList />
            </ProtectedRoute>
          }
        />
        <Route
          path="add"
          element={
            <ProtectedRoute>
              <AddMarker />
            </ProtectedRoute>
          }
        />
        <Route
          path=":id/edit"
          element={
            <ProtectedRoute>
              <EditMarker />
            </ProtectedRoute>
          }
        />
        <Route
          path=":id/view"
          element={
            <ProtectedRoute>
              <MarkerDetail />
            </ProtectedRoute>
          }
        />
      </Route>
      {/* Media */}
      <Route path="/media">
        <Route
          index
          path="/media"
          element={
            <ProtectedRoute>
              <MediaList />
            </ProtectedRoute>
          }
        />
        <Route
          path="add"
          element={
            <ProtectedRoute>
              <AddMedia />
            </ProtectedRoute>
          }
        />
        <Route
          path=":id/edit"
          element={
            <ProtectedRoute>
              <EditMedia />
            </ProtectedRoute>
          }
        />
        <Route
          path=":id/view"
          element={
            <ProtectedRoute>
              <MediaDetail />
            </ProtectedRoute>
          }
        />
      </Route>
      {/* restaurantes */}
      <Route path="/restaurantes">
        <Route
          index
          path="/restaurantes"
          element={
            <ProtectedRoute>
              <RestaurantesList />
            </ProtectedRoute>
          }
        />
        <Route
          path="add"
          element={
            <ProtectedRoute>
              <AddRestaurante />
            </ProtectedRoute>
          }
        />
        <Route
          path=":id/edit"
          element={
            <ProtectedRoute>
              <EditRestaurante />
            </ProtectedRoute>
          }
        />
        <Route
          path=":id/view"
          element={
            <ProtectedRoute>
              <RestauranteDetail />
            </ProtectedRoute>
          }
        />
      </Route>
      {/* posts */}
      <Route path="/posts">
        <Route
          index
          path="/posts"
          element={
            <ProtectedRoute>
              <PostList />
            </ProtectedRoute>
          }
        />
        <Route
          path="add"
          element={
            <ProtectedRoute>
              <AddPost />
            </ProtectedRoute>
          }
        />
        <Route
          path=":id/edit"
          element={
            <ProtectedRoute>
              <EditPost />
            </ProtectedRoute>
          }
        />
        <Route
          path=":id/view"
          element={
            <ProtectedRoute>
              <PostDetails />
            </ProtectedRoute>
          }
        />
      </Route>
      <Route path="*" element={<Error404 />} />
    </Routes>
  );
};

export default Root;
