// component to protect routes with firebase auth react-router v6
import React from "react";
import useAuthentication from "hooks/useAuthentication";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const { user, loading } = useAuthentication();

  if (loading) {
    return <div></div>;
  }

  if (!user) {
    return <Navigate to="/" />;
  }

  return children;
};

export default ProtectedRoute;
