// components/OperariosDetail.js
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Heading, Text, VStack } from "@chakra-ui/react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "config/firebase";
import Sidebar from "components/layouts/Sidebar";

const ViviendaDetail = () => {
  console.log("ViviendaDetail");
  const { id } = useParams();
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      const userDoc = await getDoc(doc(db, "alojamientos", id));
      setUser({ id: userDoc.id, ...userDoc.data() });
    };

    fetchUser();
  }, [id]);

  if (!user) {
    return <Text>Cargando usuario...</Text>;
  }

  return (
    <Sidebar>
      <Box>
        <VStack spacing={4}>
          <Heading as="h2">{user.name}</Heading>
          {/* <Text>Email: {user.email}</Text>
          <Text>Teléfono: {user.phone}</Text> */}
          {user.isActive && <Text>Activo</Text>}
          {/* Agrega aquí más campos según sea necesario */}
        </VStack>
      </Box>
    </Sidebar>
  );
};

export default ViviendaDetail;
