import { useState } from "react";
import {
  Box,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
  VStack,
  useToast,
  Image,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import {
  collection,
  addDoc,
  serverTimestamp,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db, storage } from "config/firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { Formik, Form, Field } from "formik";
import { v4 as uuidv4 } from "uuid";
import SimpleMDEEditor from "react-simplemde-editor";
import Markdown from "components/MarkdownReader";
import slugify from "slugify";

function PostForm({ initialValues, isEditing = false }) {
  const navigate = useNavigate();
  const toast = useToast();
  const [deletedImages, setDeletedImages] = useState([]);
  const [existingImages, setExistingImages] = useState([]);
  const [showPreview, setShowPreview] = useState(false);

  const handleSubmit = async (values, actions) => {
    try {
      // Upload the new images to Firebase Storage
      const newImageURLs = [];
      for (let image of existingImages) {
        const storageRef = ref(storage, `images/${uuidv4()}`);
        await uploadBytes(storageRef, image);
        const imageURL = await getDownloadURL(storageRef);
        newImageURLs.push(imageURL);
      }

      // Create or update the post in Firestore
      const postData = {
        title: values.title,
        titleLowerCase: {
          es: values.title.es.toLowerCase() || "",
          en: values.title.en.toLowerCase() || "",
        },
        content: values.content,
        images: [
          ...(values.images
            ? values.images.filter((img) => !deletedImages.includes(img))
            : []),
          ...newImageURLs,
        ],
        createdAt: isEditing ? values.createdAt : serverTimestamp(),
        startDate: values.startDate,
        endDate: values.endDate,
        slug: slugify(values.title.es, { lower: true }),
      };
      console.log("postData", postData);
      if (isEditing) {
        const postRef = doc(db, "posts", values.id);
        await updateDoc(postRef, postData);
        toast({
          title: "Post actualizado",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        await addDoc(collection(db, "posts"), postData);
        toast({
          title: "Post creado",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }

      // Redirige al usuario a la página principal
      navigate("/posts");
    } catch (error) {
      console.log(error);
      actions.setStatus(error.message);
      actions.setSubmitting(false);
    }
  };
  console.log("initialValues", initialValues);
  return (
    <Box>
      <Heading>{isEditing ? "Editar artículo" : "Nuevo artículo"}</Heading>
      <Formik
        initialValues={
          initialValues || {
            title: {
              en: "",
              es: "",
              // Agrega más idiomas según sea necesario
            },
            content: {
              en: "",
              es: "",
              // Agrega más idiomas según sea necesario
            },
            images: [],
            slug: "",
            startDate: "",
            endDate: "",
          }
        }
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({ values, status, setFieldValue, isSubmitting }) => (
          <Form>
            {/* Resto del código... */}
            <Field name="title.es">
              {({ field }) => (
                <FormControl>
                  <FormLabel>Título (es)</FormLabel>
                  <Input
                    {...field}
                    value={field.value}
                    onChange={field.onChange}
                  />
                </FormControl>
              )}
            </Field>
            <Field name="title.en">
              {({ field }) => (
                <FormControl>
                  <FormLabel>Título (en)</FormLabel>
                  <Input
                    {...field}
                    value={field.value}
                    onChange={field.onChange}
                  />
                </FormControl>
              )}
            </Field>

            {/* Agrega más campos de idioma según sea necesario */}
            <Field name="content.es">
              {({ field }) => (
                <FormControl>
                  <FormLabel>Contenido (es)</FormLabel>
                  <SimpleMDEEditor
                    value={field.value}
                    onChange={(value) => setFieldValue("content.es", value)} // Actualiza el valor usando setFieldValue
                  />
                </FormControl>
              )}
            </Field>
            <Field name="content.en">
              {({ field }) => (
                <FormControl>
                  <FormLabel>Contenido (en)</FormLabel>
                  <SimpleMDEEditor
                    value={field.value}
                    onChange={(value) => setFieldValue("content.en", value)} // Actualiza el valor usando setFieldValue
                  />
                </FormControl>
              )}
            </Field>

            {/* Agrega más campos de idioma según sea necesario */}
            {/* Resto del código... */}

            {/* <Field name="images">
              {({ field }) => (
                <FormControl>
                  <FormLabel>Imágenes</FormLabel>
                  <Input
                    type="file"
                    multiple
                    onChange={(event) => {
                      const files = Array.from(event.target.files);
                      setFieldValue(
                        "images",
                        [...field.value, ...files],
                        false
                      );
                    }}
                  />
                  <VStack mt={2} spacing={2}>
                    {values.images?.map((image, index) => (
                      <Box key={index}>
                        <Button
                          size="xs"
                          colorScheme="red"
                          onClick={() => {
                            setFieldValue(
                              "images",
                              values.images?.filter((img) => img !== image),
                              false
                            );
                            if (isEditing) {
                              setDeletedImages([...deletedImages, image]);
                            }
                          }}
                        >
                          Eliminar
                        </Button>
                        <img
                          src={URL.createObjectURL(image)}
                          alt={`Imagen ${index + 1}`}
                          width={200}
                        />
                      </Box>
                    ))}
                  </VStack>
                </FormControl>
              )}
            </Field> */}

            <FormControl>
              <FormLabel>Imágenes</FormLabel>
              <Input
                type="file"
                multiple
                onChange={
                  (event) => setExistingImages(Array.from(event.target.files)) // actualiza existingImages en lugar de images
                }
              />
              {/* <FormControl>
                <FormLabel>Imágenes existentes</FormLabel>
                {values.images.map((image, i) => (
                  <Box key={i}>
                    <Image src={image} alt={`Image ${i}`} />
                    <Button
                      onClick={() =>
                        setDeletedImages([...deletedImages, image])
                      }
                    >
                      Eliminar imagen
                    </Button>
                  </Box>
                ))}
              </FormControl> */}
              {values.images?.map((image, i) => (
                <Box key={i}>
                  <Image src={image} alt={`Image ${i}`} />
                  <Button
                    onClick={() =>
                      setFieldValue(
                        "images",
                        values.images.filter((url) => url !== image)
                      )
                    }
                  >
                    Eliminar imagen
                  </Button>
                </Box>
              ))}
            </FormControl>

            {/* Resto del código... */}
            <Field name="slug">
              {({ field }) => (
                <FormControl>
                  <FormLabel>Slug</FormLabel>
                  <Input
                    {...field}
                    value={field.value}
                    onChange={field.onChange}
                  />
                </FormControl>
              )}
            </Field>

            <Button
              mt={4}
              colorScheme="teal"
              isLoading={isSubmitting}
              type="submit" // Cambiado de onClick a type="submit"
            >
              {isEditing ? "Actualizar" : "Crear"}
            </Button>
            {/* {status && (
              <Alert mt={4} status="error">
                <AlertIcon />
                {status}
              </Alert>
            )} */}
          </Form>
        )}
      </Formik>
    </Box>
  );
}

export default PostForm;
