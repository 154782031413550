// components/SignUp.js
import { Box } from "@chakra-ui/react";

import Sidebar from "components/layouts/Sidebar";
import MediaForm from "components/Form/MediaForm";

const AddMedia = () => {
  return (
    <Sidebar>
      <Box>
        <MediaForm />
      </Box>
    </Sidebar>
  );
};

export default AddMedia;
