// components/UserListItem.js
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Spacer,
  Tag,
  TagLabel,
  Text,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { toDistanceFormatSpanish } from "utils/formatDate";
import { deleteOperario, desactiveOperario } from "services/operarios";
import { useState } from "react";
import ConfirmModal from "components/ConfirmModal";
import { FiEdit, FiEye, FiTrash } from "react-icons/fi";

const AlojamientoItem = ({ item, handleToggleUser, handleRemoveUser }) => {
  const createdAtDate = item?.createdAt?.toDate();
  const createdAtDateSpanish = toDistanceFormatSpanish(
    createdAtDate || new Date()
  );
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const navigate = useNavigate();

  const handleViewClick = () => {
    navigate(`/alojamientos/${item.id}/view`);
  };
  const handleEditClick = () => {
    navigate(`/alojamientos/${item.id}/edit`);
  };

  const handleDeleteClick = () => {
    handleRemoveUser(item.id);
    setIsConfirmOpen(false);
  };

  return (
    <Box borderWidth="1px" borderRadius="lg" padding="4" width="100%">
      <Box display="flex" flexDirection={["column", "column", "column", "row"]}>
        <Box>
          <Text fontWeight="bold">{item.name}</Text>
          <Text fontSize="sm">{item.email}</Text>
          <Text fontSize="sm">{item.phone}</Text>
          {item.isActive ? (
            <Tag colorScheme="green">
              <TagLabel>Activo</TagLabel>
            </Tag>
          ) : (
            <Tag colorScheme="red">
              <TagLabel>Inactivo</TagLabel>
            </Tag>
          )}
          <Box>
            <Tag>
              <TagLabel>{createdAtDateSpanish}</TagLabel>
            </Tag>
          </Box>
        </Box>
        <Spacer />
        <ButtonGroup size="sm" mt={[5, 5, 5, 0]}>
          <Button onClick={handleViewClick}>
            <FiEye />
          </Button>
          <Button onClick={handleEditClick}>
            <FiEdit />
          </Button>

          <Button colorScheme="red" onClick={() => setIsConfirmOpen(true)}>
            <FiTrash />
          </Button>
          <ConfirmModal
            isOpen={isConfirmOpen}
            onClose={() => setIsConfirmOpen(false)}
            onConfirm={handleDeleteClick}
            title="Eliminar usuario"
            message={`¿Estás seguro de que deseas eliminar a ${item.name}?`}
          />
          <Button
            colorScheme={item.isActive ? "yellow" : "gray"}
            onClick={() => handleToggleUser(item.id, item.isActive)}
          >
            {item.isActive ? "Desactivar" : "Activar"}
          </Button>
        </ButtonGroup>
      </Box>
    </Box>
  );
};

export default AlojamientoItem;
