// components/SignUp.js
import { Box } from "@chakra-ui/react";

import Sidebar from "components/layouts/Sidebar";
import RestauranteForm from "components/Form/RestauranteForm";

const AddRestaurante = () => {
  return (
    <Sidebar>
      <Box>
        <RestauranteForm />
      </Box>
    </Sidebar>
  );
};

export default AddRestaurante;
