import base from "./base";
import customization from "./customization";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...base,
  colors: {
    ...base.colors,
    ...customization, //Currently we only suppor the color changes in customization
  },
};
