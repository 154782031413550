import { useEffect, useState } from "react";
import { Box } from "@chakra-ui/react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "config/firebase";
import Sidebar from "components/layouts/Sidebar";
import { useParams } from "react-router-dom";
import RestauranteForm from "components/Form/RestauranteForm";

const EditRestaurante = () => {
  const { id } = useParams();

  console.log(id);
  const [post, setPost] = useState(null);

  useEffect(() => {
    if (!id) return;

    const fetchPost = async () => {
      const postRef = doc(db, "restaurantes", id);
      const postDoc = await getDoc(postRef);

      if (postDoc.exists()) {
        setPost({ id: postDoc.id, ...postDoc.data() });
      } else {
        alert("No such post!");
      }
    };

    fetchPost();
  }, [id]);

  console.log(post);

  if (!post) return "Loading...";

  return (
    <Sidebar>
      <Box>
        {post && <RestauranteForm initialValues={post} isEditing={true} />}
      </Box>
    </Sidebar>
  );
};

export default EditRestaurante;
