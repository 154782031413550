// components/OperariosDetail.js
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Heading, Text, VStack } from "@chakra-ui/react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "config/firebase";
import Sidebar from "components/layouts/Sidebar";

const MarkerDetail = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      const dataDoc = await getDoc(doc(db, "restaurantes", id));
      setData({ id: dataDoc.id, ...dataDoc.data() });
    };

    fetchUser();
  }, [id]);

  if (!data) {
    return <Text>Cargando restaurante...</Text>;
  }

  return (
    <Sidebar>
      <Box>
        <VStack spacing={4}>
          <Heading as="h2">{data.name}</Heading>
          {/* <Text>Email: {user.email}</Text>
          <Text>Teléfono: {user.phone}</Text> */}
          {/* {data.isActive && <Text>Activo</Text>} */}
          {/* Agrega aquí más campos según sea necesario */}
        </VStack>
      </Box>
    </Sidebar>
  );
};

export default MarkerDetail;
