// components/SignUp.js
import { Box } from "@chakra-ui/react";

import Sidebar from "components/layouts/Sidebar";
import RutaForm from "components/Form/RutaForm";

const AddRuta = () => {
  return (
    <Sidebar>
      <Box>
        <RutaForm />
      </Box>
    </Sidebar>
  );
};

export default AddRuta;
