// components/SignUp.js
import { Box } from "@chakra-ui/react";

import Sidebar from "components/layouts/Sidebar";
import PostForm from "components/Form/PostForm";

const AddPost = () => {
  return (
    <Sidebar>
      <Box>
        <PostForm />
      </Box>
    </Sidebar>
  );
};

export default AddPost;
