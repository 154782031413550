import { useState, useEffect } from "react";
import {
  Box,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  Grid,
  Text,
  Select,
  Image,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import {
  collection,
  addDoc,
  serverTimestamp,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db, storage } from "config/firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import styled from "styled-components";
import { FiStar, FiX } from "react-icons/fi";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { MapContainerStyle, center } from "constants/map";

function AlojamientoForm({ initialValues, isEditing = false }) {
  const [imageUrl, setImageUrl] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (initialValues && initialValues.image) {
      setImageUrl(initialValues.image);
    }
  }, [initialValues]);

  const validationSchema = Yup.object()
    .shape({
      name: Yup.string().required("El nombre es requerido"),
      position: Yup.object().shape({
        lat: Yup.number().required("La latitud es requerida"),
        lng: Yup.number().required("La longitud es requerida"),
      }),
      content: Yup.object().shape({
        es: Yup.string(),
        en: Yup.string(),
      }),

      image: Yup.mixed(),
      imageURL: Yup.string(),

      url: Yup.string(),
    })
    .test("imageURL", "La imagen es requerida", (value, { parent }) => {
      if (!value && !parent.imageURL) {
        return false;
      }
      return true;
    });

  const handleSubmit = async (values, actions) => {
    try {
      let newImageURL = "";

      // Check if values.image is a File instance and not empty
      if (values.image instanceof File && values.image.size > 0) {
        const storageRef = ref(storage, `images/${uuidv4()}`);
        await uploadBytes(storageRef, values.image);
        newImageURL = await getDownloadURL(storageRef);
      } else {
        // Keep old image url if new image is not selected
        newImageURL = values.image;
      }

      const postData = {
        name: values.name || "",
        nameLowerCase: values.name?.toLowerCase() || "",
        stars: values.stars || "",
        address: values.address || "",
        content: {
          es: values.content?.es || "",
          en: values.content?.en || "",
        },
        image: newImageURL || "",
        position: values.position,
        type: values.type || "",
        url: values.url || "",
        phone: values.phone || "",
        createdAt: isEditing ? values.createdAt : serverTimestamp(),
        updatedAt: serverTimestamp(),
      };
      console.log("postData", postData);

      if (isEditing) {
        const postRef = doc(db, "alojamientos", values.id);
        await updateDoc(postRef, postData);
      } else {
        await addDoc(collection(db, "alojamientos"), postData);
      }

      navigate("/alojamientos");
    } catch (error) {
      console.log(error);
      actions.setStatus(error.message);
      actions.setSubmitting(false);
    }
  };

  const handleMapClick = (event, setFieldValue) => {
    const { latLng } = event;
    const lat = latLng.lat();
    const lng = latLng.lng();
    const newPosition = { lat, lng };
    setFieldValue("position", newPosition);
  };

  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  const [scaledSize, setScaledSize] = useState(null);

  useEffect(() => {
    if (typeof window !== "undefined" && isLoaded) {
      const size = new window.google.maps.Size(40, 53.18);
      setScaledSize(size);
    }
  }, [isLoaded]);

  if (loadError) {
    return <div>Error loading Google Maps</div>;
  }

  const handleDrop = (event, form) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      form.setFieldValue("image", [file]);
      setImageUrl(URL.createObjectURL(file));
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  return (
    <Box>
      <Heading>
        {isEditing ? "Editar alojamiento" : "Nuevo alojamiento"}
      </Heading>
      <Formik
        initialValues={
          initialValues || {
            type: "",
            name: "",
            stars: "",
            address: "",
            phone: "",
            url: "",
            content: {
              es: "",
              en: "",
            },
            image: "",
            position: {
              lat: "",
              lng: "",
            },

            createdAt: serverTimestamp(),
          }
        }
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({ values, status, setFieldValue, isSubmitting, errors }) => (
          <Form>
            {console.log(values)}
            {console.log(errors)}
            <Field name="type">
              {({ field }) => (
                <FormControl>
                  <FormLabel>Categoría</FormLabel>
                  <Select {...field}>
                    <option value="">Selecciona una categoría</option>
                    <option value="Hoteles">Hoteles</option>
                    <option value="Hostales">Hostales</option>
                    <option value="Otros alojamientos">
                      Otros alojamientos
                    </option>
                  </Select>
                </FormControl>
              )}
            </Field>
            <Field name="name">
              {({ field }) => (
                <FormControl>
                  <FormLabel>Título</FormLabel>
                  <Input {...field} />
                </FormControl>
              )}
            </Field>
            <ErrorMessage
              name={"name"}
              render={(msg) => (
                <Text color="red.400" fontSize="sm">
                  {msg}
                </Text>
              )}
            />
            <Field name="address">
              {({ field }) => (
                <FormControl>
                  <FormLabel>Dirección</FormLabel>
                  <Input {...field} />
                </FormControl>
              )}
            </Field>
            <ErrorMessage
              name={"address"}
              render={(msg) => (
                <Text color="red.400" fontSize="sm">
                  {msg}
                </Text>
              )}
            />

            <FormControl id="stars">
              <FormLabel>Estrellas</FormLabel>
              {/* Array of 5 Icon button with setFieldValue */}
              {Array.from({ length: 5 }, (_, i) => (
                <Button
                  key={i}
                  variant="ghost"
                  colorScheme="yellow"
                  onClick={() => setFieldValue("stars", i + 1)}
                >
                  {i < values.stars ? <FiStar fill="yellow" /> : <FiStar />}
                </Button>
              ))}
              {/* reset starts */}
              {values.stars > 0 && (
                <Button
                  variant="white"
                  colorScheme="yellow"
                  onClick={() => setFieldValue("stars", 0)}
                >
                  <FiX />
                </Button>
              )}
            </FormControl>
            <Field name="content.es">
              {({ field }) => (
                <FormControl>
                  <FormLabel>Contenido (es)</FormLabel>
                  <Input {...field} />
                </FormControl>
              )}
            </Field>
            <Field name="content.en">
              {({ field }) => (
                <FormControl>
                  <FormLabel>Contenido (en)</FormLabel>
                  <Input {...field} />
                </FormControl>
              )}
            </Field>

            <Field name="position.lat">
              {({ field }) => (
                <FormControl>
                  <FormLabel>Latitud</FormLabel>
                  <Input {...field} />
                </FormControl>
              )}
            </Field>
            <ErrorMessage
              name={"position.lat"}
              render={(msg) => (
                <Text color="red.400" fontSize="sm">
                  {msg}
                </Text>
              )}
            />
            <Field name="position.lng">
              {({ field }) => (
                <FormControl>
                  <FormLabel>Longitud</FormLabel>
                  <Input {...field} />
                </FormControl>
              )}
            </Field>
            <ErrorMessage
              name={"position.lng"}
              render={(msg) => (
                <Text color="red.400" fontSize="sm">
                  {msg}
                </Text>
              )}
            />
            <Field name="url">
              {({ field }) => (
                <FormControl>
                  <FormLabel>Página web</FormLabel>
                  <Input {...field} />
                </FormControl>
              )}
            </Field>
            <Field name="phone">
              {({ field }) => (
                <FormControl>
                  <FormLabel>Teléfono</FormLabel>
                  <Input {...field} />
                </FormControl>
              )}
            </Field>

            <FormControl>
              <FormLabel>Imagen</FormLabel>
              <Field name="image">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={form.errors.image && form.touched.image}
                    onDrop={(event) => handleDrop(event, form)}
                    onDragOver={handleDragOver}
                  >
                    <Input
                      type="file"
                      accept="image/*"
                      onChange={(event) => {
                        const file = event.currentTarget.files[0];
                        if (file) {
                          form.setFieldValue("image", file);
                          setImageUrl(URL.createObjectURL(file));
                        } else {
                          form.setFieldValue("image", null);
                          setImageUrl("");
                        }
                      }}
                    />
                    <ErrorMessage name="image" component={Text} />
                  </FormControl>
                )}
              </Field>
              {imageUrl && (
                <Box>
                  <Image src={imageUrl} alt="Preview" w={"100px"} />
                </Box>
              )}
            </FormControl>
            {isLoaded && (
              <GoogleMap
                mapContainerStyle={MapContainerStyle}
                center={center}
                zoom={16}
                onClick={(event) => handleMapClick(event, setFieldValue)}
                options={{
                  disableDefaultUI: true,
                  clickableIcons: false,
                  mapTypeId: "satellite",
                }}
              >
                <>
                  {" "}
                  <Marker
                    position={values.position}
                    // position={center}
                    // icon={renderIcon(values.type)}
                    draggable={true}
                    onDragEnd={(event) => handleMapClick(event, setFieldValue)}
                  />
                </>
              </GoogleMap>
            )}
            <Button
              mt={4}
              colorScheme="teal"
              isLoading={isSubmitting}
              type="submit"
            >
              {isEditing ? "Actualizar" : "Crear"}
            </Button>
            {status && <Text color="red">{status}</Text>}
          </Form>
        )}
      </Formik>
    </Box>
  );
}

export default AlojamientoForm;
