import { createGlobalStyle } from "styled-components";
// import { reset } from "styled-reset";

const GlobalStyle = createGlobalStyle`
  
  html {
    font-size: 100%; 
  }
  html, button, input, select, textarea {
    font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  }
  body {
    background-color: ${({ theme }) => theme.colors.gray1};
    color: ${({ theme }) => theme.colors.textColor};
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    font-feature-settings: "liga","kern";
    font-variant-numeric: proportional-nums;
  }
  * {
    box-sizing: border-box;
  }
  sub {

    font-size: 0.75rem;
  }
/* Change autocomplete the white to any color */
  /* input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus, 
  input:-webkit-autofill:active
  {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  } */
  .SortableItem {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 0 20px;
  background-color: #fff;
  border-bottom: 1px solid #efefef;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #333;
  font-weight: 500;
}
.SortableHelper {
  box-shadow: rgba(0, 0, 0, 0.075) 0px 1px 6px, rgba(0, 0, 0, 0.075) 0px 1px 4px;
  background-color: rgba(255, 255, 255, 0.8);
  cursor: row-resize;
}
:root {
    --ck-color-mention-background: hsla(341, 100%, 30%, 0.1) !important;
    --ck-color-mention-text: hsl(341, 100%, 30%) !important;
    --ck-highlight-marker-blue: hsl(201, 97%, 72%) !important;
    --ck-highlight-marker-green: hsl(120, 93%, 68%) !important;
    --ck-highlight-marker-pink: hsl(345, 96%, 73%) !important;
    --ck-highlight-marker-yellow: hsl(60, 97%, 73%) !important;
    --ck-highlight-pen-green: hsl(112, 100%, 27%) !important;
    --ck-highlight-pen-red: hsl(0, 85%, 49%) !important;
    --ck-image-style-spacing: 1.5em !important;
    --ck-todo-list-checkmark-size: 16px !important;
}

/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-tiny {
    font-size: .7em !important;
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-small {
    font-size: .85em !important;
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-big {
    font-size: 1.4em !important;
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-huge {
    font-size: 1.8em !important;
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-yellow {
    background-color: var(--ck-highlight-marker-yellow) !important;
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-green {
    background-color: var(--ck-highlight-marker-green) !important;
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-pink {
    background-color: var(--ck-highlight-marker-pink) !important;
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-blue {
    background-color: var(--ck-highlight-marker-blue) !important;
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .pen-red {
    color: var(--ck-highlight-pen-red) !important;
    background-color: transparent !important;
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .pen-green {
    color: var(--ck-highlight-pen-green) !important;
    background-color: transparent !important;
}
/* ckeditor5-image/theme/image.css */
.ck-content .image {
    display: table !important;
    clear: both !important;
    text-align: center !important;
    margin: 1em auto !important;
}
/* ckeditor5-image/theme/image.css */
.ck-content .image img {
    display: block !important;
    margin: 0 auto !important;
    max-width: 100% !important;
    min-width: 50px !important;
}
/* ckeditor5-image/theme/imagecaption.css */
.ck-content .image > figcaption {
    display: table-caption !important;
    caption-side: bottom !important;
    word-break: break-word !important;
    color: hsl(0, 0%, 20%) !important;
    background-color: hsl(0, 0%, 97%) !important;
    padding: .6em !important;
    font-size: .75em !important;
    outline-offset: -1px !important;
}
/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized {
    max-width: 100% !important;
    display: block !important;
    box-sizing: border-box !important;
}
/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized img {
    width: 100% !important;
}
/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized > figcaption {
    display: block !important;
}
/* ckeditor5-horizontal-line/theme/horizontalline.css */
.ck-content hr {
    margin: 15px 0 !important;
    height: 4px !important;
    background: hsl(0, 0%, 87%) !important;
    border: 0 !important;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-side {
    float: right !important;
    margin-left: var(--ck-image-style-spacing) !important;
    max-width: 50% !important;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-left {
    float: left !important;
    margin-right: var(--ck-image-style-spacing) !important;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-center {
    margin-left: auto !important;
    margin-right: auto !important;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-right {
    float: right !important;
    margin-left: var(--ck-image-style-spacing) !important;
}
/* ckeditor5-block-quote/theme/blockquote.css */
.ck-content blockquote {
    overflow: hidden !important;
    padding-right: 1.5em !important;
    padding-left: 1.5em !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    font-style: italic !important;
    border-left: solid 5px hsl(0, 0%, 80%) !important;
}
/* ckeditor5-block-quote/theme/blockquote.css */
.ck-content[dir="rtl"] blockquote {
    border-left: 0 !important;
    border-right: solid 5px hsl(0, 0%, 80%) !important;
}
/* ckeditor5-basic-styles/theme/code.css */
.ck-content code {
    background-color: hsla(0, 0%, 78%, 0.3) !important;
    padding: .15em !important;
    border-radius: 2px !important;
}
/* ckeditor5-table/theme/table.css */
.ck-content .table {
    margin: 1em auto !important;
    display: table !important;
}
/* ckeditor5-table/theme/table.css */
.ck-content .table table {
    border-collapse: collapse !important;
    border-spacing: 0 !important;
    width: 100% !important;
    height: 100% !important;
    border: 1px double hsl(0, 0%, 70%) !important;
}
/* ckeditor5-table/theme/table.css */
.ck-content .table table td,
.ck-content .table table th {
    min-width: 2em !important;
    padding: .4em !important;
    border: 1px solid hsl(0, 0%, 75%) !important;
}
/* ckeditor5-table/theme/table.css */
.ck-content .table table th {
    font-weight: bold !important;
    background: hsla(0, 0%, 0%, 5%) !important;
}
/* ckeditor5-table/theme/table.css */
.ck-content[dir="rtl"] .table th {
    text-align: right !important;
}
/* ckeditor5-table/theme/table.css */
.ck-content[dir="ltr"] .table th {
    text-align: left !important;
}
/* ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break {
    position: relative !important;
    clear: both !important;
    padding: 5px 0 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}
/* ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break::after {
    content: '' !important;
    position: absolute !important;
    border-bottom: 2px dashed hsl(0, 0%, 77%) !important;
    width: 100% !important;
}
/* ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break__label {
    position: relative !important;
    z-index: 1 !important;
    padding: .3em .6em !important;
    display: block !important;
    text-transform: uppercase !important;
    border: 1px solid hsl(0, 0%, 77%) !important;
    border-radius: 2px !important;
    font-family: Helvetica, Arial, Tahoma, Verdana, Sans-Serif !important;
    font-size: 0.75em !important;
    font-weight: bold !important;
    color: hsl(0, 0%, 20%) !important;
    background: hsl(0, 0%, 100%) !important;
    box-shadow: 2px 2px 1px hsla(0, 0%, 0%, 0.15) !important;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
}
/* ckeditor5-media-embed/theme/mediaembed.css */
.ck-content .media {
    clear: both !important;
    margin: 1em 0 !important;
    display: block !important;
    min-width: 15em !important;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list {
    list-style: none !important;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list li {
    margin-bottom: 5px !important;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list li .todo-list {
    margin-top: 5px !important;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input {
    -webkit-appearance: none !important;
    display: inline-block !important;
    position: relative !important;
    width: var(--ck-todo-list-checkmark-size) !important;
    height: var(--ck-todo-list-checkmark-size) !important;
    vertical-align: middle !important;
    border: 0 !important;
    left: -25px !important;
    margin-right: -15px !important;
    right: 0 !important;
    margin-left: 0 !important;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input::before {
    display: block !important;
    position: absolute !important;
    box-sizing: border-box !important;
    content: '' !important;
    width: 100% !important;
    height: 100% !important;
    border: 1px solid hsl(0, 0%, 20%) !important;
    border-radius: 2px !important;
    transition: 250ms ease-in-out box-shadow, 250ms ease-in-out background, 250ms ease-in-out border !important;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input::after {
    display: block !important;
    position: absolute !important;
    box-sizing: content-box !important;
    pointer-events: none !important;
    content: '' !important;
    left: calc( var(--ck-todo-list-checkmark-size) / 3 ) !important;
    top: calc( var(--ck-todo-list-checkmark-size) / 5.3 ) !important;
    width: calc( var(--ck-todo-list-checkmark-size) / 5.3 ) !important;
    height: calc( var(--ck-todo-list-checkmark-size) / 2.6 ) !important;
    border-style: solid !important;
    border-color: transparent !important;
    border-width: 0 calc( var(--ck-todo-list-checkmark-size) / 8 ) calc( var(--ck-todo-list-checkmark-size) / 8 ) 0 !important;
    transform: rotate(45deg) !important;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input[checked]::before {
    background: hsl(126, 64%, 41%) !important;
    border-color: hsl(126, 64%, 41%) !important;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input[checked]::after {
    border-color: hsl(0, 0%, 100%) !important;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label .todo-list__label__description {
    vertical-align: middle !important;
}
/* ckeditor5-html-embed/theme/htmlembed.css */
.ck-content .raw-html-embed {
    margin: 1em auto !important;
    min-width: 15em !important;
    font-style: normal !important;
}
/* ckeditor5-code-block/theme/codeblock.css */
.ck-content pre {
    padding: 1em !important;
    color: hsl(0, 0%, 20.8%) !important;
    background: hsla(0, 0%, 78%, 0.3) !important;
    border: 1px solid hsl(0, 0%, 77%) !important;
    border-radius: 2px !important;
    text-align: left !important;
    direction: ltr !important;
    tab-size: 4 !important;
    white-space: pre-wrap !important;
    font-style: normal !important;
    min-width: 200px !important;
}
/* ckeditor5-code-block/theme/codeblock.css */
.ck-content pre code {
    background: unset !important;
    padding: 0 !important;
    border-radius: 0 !important;
}
/* ckeditor5-mention/theme/mention.css */
.ck-content .mention {
    background: var(--ck-color-mention-background) !important;
    color: var(--ck-color-mention-text) !important;
}
@media print {
    /* ckeditor5-page-break/theme/pagebreak.css */
    .ck-content .page-break {
        padding: 0 !important;
    }
    /* ckeditor5-page-break/theme/pagebreak.css */
    .ck-content .page-break::after {
        display: none !important;
    }
}

.my-swal {
  z-index: 10000 !important;
}
.leaflet-pane {
    z-index: 2 !important;
}
.leaflet-top {
    z-index: 2 !important;
}
.leaflet-bottom {
    z-index: 2 !important;
}
/* .modal-swalfire {
    background: ${({ theme }) => theme.colors.gray1};
    padding: 20px;
    padding: 10px;
} */

.modal-swalfire {
    list-style: none;
    counter-reset: my-awesome-counter;
    background: ${({ theme }) => theme.colors.gray1};
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 20px;
    
}
.modal-swalfire-li{
    counter-increment: my-awesome-counter;
    margin-bottom: 10px;
    font-size: 0.9rem;
}
.modal-swalfire-li::before {
    content: counter(my-awesome-counter) "";
    background: ${({ theme }) => theme.colors.corporate};
    color: ${({ theme }) => theme.colors.white};
    width: 25px;
    height: 25px;
    padding: 5px;
    font-weight: bold;
    margin-right: 10px;
    border-radius: 50%;
    box-shadow: 0px 4px 8px rgba(0,0,0,0.12);
    display: inline-flex;
    justify-content: center;
    align-items: center;
}
.modal-swalfire-p {
font-size: 0.9rem;
}

a {
    color: ${({ theme }) => theme.colors.corporate};
}


/* Custom React Datepicker Theme */
.react-datepicker {
  font-family: "CircularStd", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  background-color: #fff;
  border-radius: 12px;
  border: none;
  box-shadow: 0 0 0 1px rgba(72, 72, 72, 0.08), 0 8px 16px rgba(72, 72, 72, 0.16), 0 16px 32px rgba(72, 72, 72, 0.16);
}

.react-datepicker__header {
  background-color: #fff;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom: none;
  padding: 16px 0;
  position: relative;
  box-shadow: 0 8px 16px rgba(72, 72, 72, 0.16), 0 16px 32px rgba(72, 72, 72, 0.16);
}

.react-datepicker__current-month,
.react-datepicker-year-header {
  font-size: 22px;
  font-weight: bold;
  line-height: 1.3;
  margin: 0 0 16px;
}

.react-datepicker__day-name,
.react-datepicker__day {
  color: #484848;
  font-size: 14px;
}

.react-datepicker__day {
  padding: 8px;
  border-radius: 50%;
  margin: 0 4px 8px;
  transition: background-color 0.2s ease;
}

.react-datepicker__day:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}

.react-datepicker__day--today,
.react-datepicker__day--selected {
  background-color: #3ca8e0;
  color: #fff;
  font-weight: bold;
}

.react-datepicker__month-dropdown-container--select,
.react-datepicker__year-dropdown-container--select {
  font-size: 16px;
  margin: 0 16px 16px;
}

.react-datepicker__month-dropdown,
.react-datepicker__year-dropdown {
  background-color: #fff;
  border-radius: 4px;
  border: none;
  box-shadow: 0 8px 16px rgba(72, 72, 72, 0.16), 0 16px 32px rgba(72, 72, 72, 0.16);
}

.react-datepicker__month-option,
.react-datepicker__year-option {
  color: #484848;
  font-size: 16px;
  padding: 8px 16px;
  transition: background-color 0.2s ease;
}

.react-datepicker__month-option:hover,
.react-datepicker__year-option:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}

.react-datepicker__month-option--selected,
.react-datepicker__year-option--selected {
  background-color: #008489;
  color: #fff;
  font-weight: bold;
}

.react-datepicker__navigation {
  top: 16px;
  ine-height: 1;
  cursor: pointer;
  position: absolute;
  border: none;
  outline: none;
  background-color: transparent;
}

.react-datepicker__navigation--previous {
  left: 16px;
}

.react-datepicker__navigation--next {
  right: 16px;
}

.react-datepicker__navigation--previous:hover,
.react-datepicker__navigation--next:hover {
  opacity: 0.5;
}

.react-datepicker__input-container {
  position: relative;
}

.react-datepicker__input-container input {
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  border: none;
  box-shadow: 0 0 0 1px rgba(72, 72, 72, 0.08), 0 8px 16px rgba(72, 72, 72, 0.16), 0 16px 32px rgba(72, 72, 72, 0.16);
  font-size: 16px;
  font-weight: bold;
  color: #484848;
}

.react-datepicker__input-container input:focus {
  outline: none;
  box-shadow: 0 0 0 2px #3ca8e0, 0 0 0 4px rgba(60, 168, 224, 0.16);
}

.react-datepicker__triangle {
  border-bottom-color: #fff;
  border-top-width: 0;
}

.react-datepicker__triangle::before {
  border-bottom-color: #f0f0f0;
  border-top-width: 0;
}

.react-datepicker__triangle::after {
  border-bottom-color: #fff;
  border-top-width: 0;
}

.react-datepicker__time-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 16px;
}

.react-datepicker__time-box {
  width: 48%;
  margin: 8px;
}

.react-datepicker__time-box input {
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  border: none;
  box-shadow: 0 0 0 1px rgba(72, 72, 72, 0.08), 0 8px 16px rgba(72, 72, 72, 0.16), 0 16px 32px rgba(72, 72, 72, 0.16);
  font-size: 16px;
  font-weight: bold;
  color: #484848;
}

.react-datepicker__time-box input:focus {
  outline: none;
  box-shadow: 0 0 0 2px #3ca8e0, 0 0 0 4px rgba(60, 168, 224, 0.16);
}

.react-datepicker__time-box span {
  font-size: 14px;
  color: #484848;
  margin-right: 8px;
}

.react-datepicker__time-box--selected {
  background-color: #008489;
  color: #fff;
  border-radius: 8px;
  font-weight: bold;
}

.react-datepicker__time-box--selected span {
  color: #fff;
}

.react-datepicker__time-box--selected input {
  color: #fff;
}

@media (max-width: 576px) {
  .react-datepicker__time-box {
    width: 100%;
  }
}

@media (max-width: 360px) {
  .react-datepicker__current-month,
  .react-datepicker-year-header {
    font-size: 20px;
  }

  .react-datepicker__day-name,
  .react-datepicker__day {
    font-size: 12px;
  }

  .react-datepicker__month-dropdown,
  .react-datepicker__year-dropdown {
    font-size: 14px;
  }

  .react-datepicker__month-option,
  .react-datepicker__year-option {
    font-size: 12px;
    padding: 4px 8px;
  }

  .react-datepicker__input-container input {
    font-size: 14px;
  }

  .react-datepicker__time-box input {
    font-size: 14px;
  }

  .react-datepicker__time-box span {
    font-size: 12px;
  }
 

}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  line-height: unset!important;

}

.react-datepicker__triangle {
    display: none;
}
`;

export default GlobalStyle;
