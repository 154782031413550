// components/SignUp.js
import { Box } from "@chakra-ui/react";

import Sidebar from "components/layouts/Sidebar";
import AlojamientoForm from "components/Form/AlojamientoForm";

const AddAlojamiento = () => {
  return (
    <Sidebar>
      <Box>
        <AlojamientoForm />
      </Box>
    </Sidebar>
  );
};

export default AddAlojamiento;
