import styled, { keyframes } from "styled-components";
import { Link as DefaultLink } from "react-router-dom";

import { H2, Small } from "components/Typography";
import { from } from "styles/media";

const type = keyframes`
from {
  
    box-shadow: inset -3px 0px 0px #888;
  }
  to {
    box-shadow: inset -3px 0px 0px transparent;
  }`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export const Title = styled(H2)`
  font-size: 50px;
  display: inline-block;
  padding-right: 12px;
  animation: ${type} 0.5s alternate infinite;
  color: #888;
`;
