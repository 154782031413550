import React from "react";
import Root from "containers/Root";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "styles/GlobalStyle";
import { BrowserRouter } from "react-router-dom";
import theme from "styles/themes";
import { ChakraProvider } from "@chakra-ui/react";
import chakraTheme from "styles/chakra";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

const App = () => {
  return (
    <ChakraProvider theme={chakraTheme}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <BrowserRouter>
          <QueryParamProvider adapter={ReactRouter6Adapter}>
            <Root />
          </QueryParamProvider>
        </BrowserRouter>
      </ThemeProvider>
    </ChakraProvider>
  );
};

export default App;
