import * as yup from "yup";

export const initialValues = {
  email: "",
  password: "",
};

export const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Email no es válido!")
    .required("Email es requerido!"),
  password: yup.string().required("Password es requerido!"),
});

export const apiErrorHandler = (error) => {
  if (error.response) {
    // The request was made, but the server responded with a status code
    // that falls out of the range of 2xx
    // console.error(error.response);
    switch (error.response.status) {
      case 401:
        if (error.response.data) {
          console.error("error -> ", error.response.data.message);
          switch (error.response.data.message) {
            case "invalidLoginOrPassword":
              return {
                message: "Usuario o contraseña incorrectos",
                type: "error",
              };

            case "userUnauthorized":
              return {
                message:
                  "Le ha sido restringido el acceso para inciar sesión, contacte con Donadoo si desea activarlo",
                type: "error",
              };

            case "invalid or expired jwt":
              return { message: "Token ha expirado", type: "error" };

            default:
              return {
                message:
                  "Le ha sido restringido el acceso para inciar sesión, contacte con Donadoo si desea activarlo",
                type: "error",
              };
          }
        }
        break;
      case 403:
        return {
          message:
            "Le ha sido restringido el acceso para inciar sesión, contacte con Donadoo si desea activarlo",
          type: "error",
        };
      case 404:
        return { message: "No se ha encontrado el recurso", type: "error" };
      case 500:
        return { message: "Error interno del servidor", type: "error" };
      default:
        return { message: "Error desconocido", type: "error" };
    }
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.error(error.request);
  }
  // Something happened in setting up the request that triggered an Error
  console.error("Error", error.message);
  return { message: "Error desconocido", type: "error" };
};
