import { useCallback, useEffect, useState } from "react";
import {
  collection,
  doc,
  setDoc,
  getDocs,
  getDoc,
  query,
  where,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";

import { getFirestore } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { auth } from "config/firebase";
import useAuth from "hooks/useAuthentication";
import {
  setPersistence,
  browserSessionPersistence,
  signInWithEmailAndPassword,
} from "firebase/auth";

const useConnect = () => {
  const db = getFirestore(initializeApp);
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState(null);

  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      navigate("/home");
    }
  }, [user, navigate]);

  const onSubmit = (data) => {
    signInWithEmailAndPassword(auth, data.email, data.password)
      .then(async (userCredential) => {
        const user = userCredential.user;

        const usuariosRef = doc(db, "clientes", user.uid);
        await setDoc(usuariosRef, { email: user.email });
        navigate("/home");

        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log("error", errorCode, errorMessage);
        setErrorMessage(errorMessage);
      });
  };

  return {
    onsSubmit: onSubmit,
    errorMessage,
  };
};

export default useConnect;
