import React from "react";
import Sidebar from "components/layouts/Sidebar";
export default function Home() {
  return (
    <>
      <Sidebar>
        <h1>Home</h1>
      </Sidebar>
    </>
  );
}
