import { useState, useEffect } from "react";
import {
  VStack,
  Flex,
  Input,
  FormControl,
  Button,
  Heading,
} from "@chakra-ui/react";
import {
  collection,
  query,
  where,
  orderBy,
  limit,
  getDocs,
  startAfter,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "config/firebase";
import Card from "components/Cards/Agenda";
import Sidebar from "components/layouts/Sidebar";
import { useNavigate } from "react-router-dom";

const AgendaList = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [lastDoc, setLastDoc] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const fetchData = async () => {
    const dataRef = collection(db, "agenda");
    let q = query(dataRef, orderBy("createdAt", "desc"), limit(5));
    if (searchTerm) {
      const lowerCaseTerm = searchTerm.toLowerCase();
      q = query(
        dataRef,
        where("titleLowerCase.es", ">=", lowerCaseTerm),
        where("titleLowerCase.es", "<=", lowerCaseTerm + "\uf8ff"),
        orderBy("titleLowerCase.es"),
        limit(5)
      );
    }
    const data = await getDocs(q);
    const dataArray = [];
    data.forEach((doc) => {
      dataArray.push({
        id: doc.id,
        ...doc.data(),
      });
    });
    setData(dataArray);
    setLastDoc(data.docs[data.docs.length - 1]);
  };

  const fetchMoreData = async () => {
    const dataRef = collection(db, "agenda");
    let q = query(
      dataRef,
      orderBy("createdAt", "desc"),
      startAfter(lastDoc),
      limit(5)
    );
    if (searchTerm) {
      const lowerCaseTerm = searchTerm.toLowerCase();
      q = query(
        dataRef,
        where("titleLowerCase.es", ">=", lowerCaseTerm),
        where("titleLowerCase.es", "<=", lowerCaseTerm + "\uf8ff"),
        orderBy("titleLowerCase.es"),
        startAfter(lastDoc),
        limit(5)
      );
    }
    const newData = await getDocs(q);
    const dataArray = [];
    newData.forEach((doc) => {
      dataArray.push({
        id: doc.id,
        ...doc.data(),
      });
    });
    setData([...data, ...dataArray]);
    setLastDoc(newData.docs[newData.docs.length - 1]);
  };

  useEffect(() => {
    fetchData();
  }, [searchTerm]);

  const handleLoadMore = () => {
    fetchMoreData();
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleToggleUser = async (id, isActive) => {
    try {
      const docRef = doc(db, "agenda", id);
      await updateDoc(docRef, {
        isActive: !isActive,
      });
      setData(
        data.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              isActive: !isActive,
            };
          }
          return item;
        })
      );
    } catch (error) {
      console.error("Error toggling item: ", error);
    }
  };

  const handleRemoveUser = async (id) => {
    try {
      const docRef = doc(db, "agenda", id);
      await deleteDoc(docRef);
      setData(data.filter((item) => item.id !== id));
    } catch (error) {
      console.error("Error removing item: ", error);
    }
  };

  const handleCreate = () => {
    navigate(`/agenda/add`);
  };

  return (
    <Sidebar>
      <FormControl mb="4">
        <Flex justify="space-between" align="center" mb={10}>
          <Heading size="md">Agenda</Heading>
          <Button colorScheme="brand" onClick={handleCreate}>
            Crear evento
          </Button>
        </Flex>
        <Input
          type="text"
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Filtrar por nombre"
        />
      </FormControl>
      <VStack spacing={4}>
        {data.map((item) => (
          <Card
            key={item.id}
            item={item}
            handleToggleUser={handleToggleUser}
            handleRemoveUser={handleRemoveUser}
          />
        ))}
        {lastDoc && <Button onClick={handleLoadMore}>Cargar más</Button>}
      </VStack>
    </Sidebar>
  );
};

export default AgendaList;
