import React from "react";
import { Container, Title } from "./styles";

export default function Home() {
  return (
    <Container>
      <Title>No encontramos la página que buscas</Title>
    </Container>
  );
}
