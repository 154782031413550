import { useState } from "react";
import Select from "react-select";
import { Flex, Button } from "@chakra-ui/react";
import ErrorMessageText from "components/ErrorMessageText";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import es from "date-fns/locale/es";
import DatePicker, { registerLocale } from "react-datepicker";

const CustomDayPicker = ({ label, name, value, onChange, showTimeInput }) => {
  const [error, setError] = useState("");
  console.log(value);
  registerLocale("es", es);
  const formatDate = (date) => {
    return format(date, "dd MMM yyyy ⏰ HH:mm", { locale: es });
  };

  const ExampleCustomInput = ({ value, onClick }) => (
    <Button
      colorScheme="gray"
      p={6}
      onClick={onClick}
      type="button"
      border="1px solid #e2e8f0"
    >
      {/* {formatDate(value)} */}
      {value}
    </Button>
  );

  return (
    <Flex>
      <label htmlFor={name}>{label}</label>
      <DatePicker
        selected={value}
        onChange={onChange}
        showTimeInput={showTimeInput}
        timeFormat="HH:mm"
        timeIntervals={15}
        timeCaption="Hora"
        // dateFormat={showTimeInput ? "dd/MM/yyyy HH:mm" : "dd/MM/yyyy"}
        customInput={<ExampleCustomInput />}
      />
      {error && (
        <ErrorMessageText color="red.500" fontSize="sm" mt={1}>
          {error}
        </ErrorMessageText>
      )}
    </Flex>
  );
};

export default CustomDayPicker;
