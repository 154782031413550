// import

import { FiHome, FiTool, FiUser } from "react-icons/fi";
import {
  MdCameraAlt,
  MdEditDocument,
  MdImage,
  MdOutlineCalendarMonth,
  MdOutlineGpsNotFixed,
  MdOutlineLocalHotel,
  MdRestaurant,
  MdRoute,
} from "react-icons/md";

import { TbTrekking } from "react-icons/tb";

var dashRoutes = [
  // {
  //   path: "/home",
  //   name: "Home",
  //   icon: <FiHome color="inherit" />,
  // },
  {
    path: "/alojamientos",
    name: "Alojamientos",
    icon: <MdOutlineLocalHotel color="inherit" />,
  },
  {
    path: "/restaurantes",
    name: "Restaurantes",
    icon: <MdRestaurant color="inherit" />,
  },
  {
    path: "/rutas",
    name: "Rutas",
    icon: <TbTrekking color="inherit" />,
  },
  {
    path: "/agenda",
    name: "Agenda",
    icon: <MdOutlineCalendarMonth color="inherit" />,
  },
  {
    path: "/posts",
    name: "Artículos",
    icon: <MdEditDocument color="inherit" />,
  },
  {
    path: "/markers",
    name: "Markers",
    icon: <MdOutlineGpsNotFixed color="inherit" />,
  },
  {
    path: "/media",
    name: "Media",
    icon: <MdCameraAlt color="inherit" />,
  },
];

export const HOME = "/home";
export const SIGN_IN = "/";
export const LOGIN = "/";

export default dashRoutes;
