import { mode } from "@chakra-ui/theme-tools";

export const globalStyles = {
  colors: {
    gray: {
      700: "#1f2733",
    },
    brand: {
      50: "#E1F4FF",
      100: "#B4E2FF",
      200: "#84C9FF",
      300: "#54AEFF",
      400: "#2393FF",
      500: "#3CA8E0",
      600: "#0B7ECF",
      700: "#0861A8",
      800: "#05447F",
      900: "#032656",
    },
  },
  styles: {
    global: (props) => ({
      body: {
        bg: mode("gray.50", "gray.800")(props),
        fontFamily: "Helvetica, sans-serif",
      },
      html: {
        fontFamily: "Helvetica, sans-serif",
      },
    }),
  },
};
