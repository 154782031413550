// eslint-disable-next-line import/no-anonymous-default-export
export default {
  fonts: {},
  colors: {
    // black: "#000000",
    // white: "#ffffff",
    // gray1: "#F9F9F9",
    // gray2: "#F1F1F1",
    // gray3: "#C9CFCD",
    // gray4: "#828282",
    // gray5: "#5C6864",
    // gray6: "#191F1D",
    // gray7: "#191F1D",
    // iconGray: "#888E8C",
    // navyGreen: "#38433F",
    // navyGreenLight: "#F2F7F5",
    // greenExtraDark: "#178C61",
    // greenDark: "#28AA7A",
    // greenMedium: "#3DC694",
    // greenLight: "#5BDBAD",
    // greenExtraLight: "#E4F8F0",
    // redExtraDark: "#D9432E",
    // redDark: "#F2604C",
    // redMedium: "#FF7664",
    // redLight: "#FF9B8D",
    // redExtraLight: "#FFF0EE",
    // yellowDark: "#EA8D21",
    // yellowMedium: "#FFAD4F",
    // yellowLight: "#FCF2E7",
    // blueDark: "#2B71DB",
    // blueMedium: "#498DF2",
    // blueLight: "#E9F0FB",
    // fuschia: "#EF5DA8",
    // fuschiaMedium: "#F178B6",
    // fuschiaLight: "#FCDDEC",
    // corporate: "#3D7ABD",
    // corporateLight: "#aeaff7",
    // corporateMedium: "#c2e7f7",
    // secondary: "#FFAD4F",
    // water: "#146c70",
    // google: "#DB4437",
    // facebook: "#4267B2",
    // twitter: "#00acee",
    // apple: "#000000",
    // textColor: "#38433F",
  },
  shadows: {
    shadowSmallest: "0px 4px 8px rgba(0,0,0,0.12)",
    shadowSmall: "0 5px 10px rgba(0,0,0,0.12)",
    shadowMedium: "0 8px 30px rgba(0,0,0,0.12)",
    shadowLarge: "0 30px 60px rgba(0,0,0,0.12)",
    shadowHover: "0 30px 60px rgba(0,0,0,0.12)",
    shadowSticky: "0 12px 10px -10px rgba(0,0,0,0.12)",
    shadowCorporate: "0 12px 10px -10px rgba(,0,0,0.12)",
  },
  weights: {},
  zIndex: {
    link: 1,
    linkAction: 2,
    hero: 2,
    heroItem: 3,
    header: 4,
    modal: 5,
  },
};
