import { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  useToast,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { doc, getDoc, updateDoc, serverTimestamp } from "firebase/firestore";
import { db } from "config/firebase";
import Sidebar from "components/layouts/Sidebar";
import { useParams } from "react-router-dom";
import MarkerForm from "components/Form/MarkerForm";

const EditMarker = () => {
  const { id } = useParams();

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("El título es requerido"),
    position: Yup.object().shape({
      lat: Yup.number().required("La latitud es requerida"),
      lng: Yup.number().required("La longitud es requerida"),
    }),
    image: Yup.mixed()
      .required("La imagen es requerida")
      .test("is-file", "Solo se permite una imagen", (value) => {
        if (value) {
          return value.length === 1;
        }
        return true;
      }),
  });

  console.log(id);
  const [post, setPost] = useState(null);

  useEffect(() => {
    if (!id) return;

    const fetchPost = async () => {
      const postRef = doc(db, "markers", id);
      const postDoc = await getDoc(postRef);

      if (postDoc.exists()) {
        setPost({ id: postDoc.id, ...postDoc.data() });
      } else {
        alert("No such post!");
      }
    };

    fetchPost();
  }, [id]);

  console.log(post);

  if (!post) return "Loading...";

  return (
    <Sidebar>
      <Box>{post && <MarkerForm initialValues={post} isEditing={true} />}</Box>
    </Sidebar>
  );
};

export default EditMarker;
