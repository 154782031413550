import { useState } from "react";
import {
  Box,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
  VStack,
  useToast,
  Image,
  Grid,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import {
  collection,
  addDoc,
  serverTimestamp,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db, storage } from "config/firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { Formik, Form, Field } from "formik";
import { v4 as uuidv4 } from "uuid";
import SimpleMDEEditor from "react-simplemde-editor";
import DatePicker from "components/CustomDayPicker";
import slugify from "slugify";
import { fromUnixTime, isValid } from "date-fns";

function AgendaForm({ initialValues, isEditing = false }) {
  const navigate = useNavigate();
  const toast = useToast();
  const [deletedImages, setDeletedImages] = useState([]);
  const [existingImages, setExistingImages] = useState([]);
  const [showPreview, setShowPreview] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setExistingImages([...existingImages, file]);

      // Preview the image
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDeleteImage = (image) => {
    setDeletedImages([...deletedImages, image]);

    // Remove the image from the existing images array
    setExistingImages(existingImages.filter((img) => img !== image));
  };

  const handleSubmit = async (values, actions) => {
    try {
      // Upload the new images to Firebase Storage
      const newImageURLs = [];
      for (let image of existingImages) {
        const storageRef = ref(storage, `images/${uuidv4()}`);
        await uploadBytes(storageRef, image);
        const imageURL = await getDownloadURL(storageRef);
        newImageURLs.push(imageURL);
      }

      // Create or update the post in Firestore
      const postData = {
        title: values.title,
        content: values.content,
        images: [
          ...(values.images
            ? values.images.filter((img) => !deletedImages.includes(img))
            : []),
          ...newImageURLs,
        ],
        createdAt: isEditing ? values.createdAt : serverTimestamp(),
        startDate: values.startDate,
        endDate: values.endDate,
        slug: `${slugify(values.title.es, { lower: true })}-${uuidv4()}`,
      };

      if (isEditing) {
        const postRef = doc(db, "agenda", values.id);
        await updateDoc(postRef, postData);
        toast({
          title: "Evento actualizado",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        await addDoc(collection(db, "agenda"), postData);
        toast({
          title: "Evento creado",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }

      // Redirige al usuario a la página principal
      navigate("/media");
    } catch (error) {
      console.log(error);
      actions.setStatus(error.message);
      actions.setSubmitting(false);
    }
  };

  const convertTimestampToDate = (timestamp) => {
    if (timestamp && timestamp.seconds) {
      const unixTime = timestamp.seconds;
      return fromUnixTime(unixTime);
    }
    if (isValid(timestamp)) {
      return timestamp;
    }
  };

  return (
    <Box>
      <Heading>{isEditing ? "Editar evento" : "Nuevo evento"}</Heading>
      <Formik
        initialValues={
          initialValues || {
            title: {
              en: "",
              es: "",
              // Agrega más idiomas según sea necesario
            },
            content: {
              en: "",
              es: "",
              // Agrega más idiomas según sea necesario
            },
            images: [],
            startDate: "",
            endDate: "",
            createdAt: serverTimestamp(),
            slug: "",
          }
        }
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({ values, status, setFieldValue, isSubmitting }) => (
          <Form>
            {/* Resto del código... */}
            <Field name="title.es">
              {({ field }) => (
                <FormControl>
                  <FormLabel>Título (es)</FormLabel>
                  <Input
                    {...field}
                    value={field.value}
                    onChange={field.onChange}
                  />
                </FormControl>
              )}
            </Field>
            <Field name="title.en">
              {({ field }) => (
                <FormControl>
                  <FormLabel>Título (en)</FormLabel>
                  <Input
                    {...field}
                    value={field.value}
                    onChange={field.onChange}
                  />
                </FormControl>
              )}
            </Field>

            {/* Agrega más campos de idioma según sea necesario */}
            <Field name="content.es">
              {({ field }) => (
                <FormControl>
                  <FormLabel>Contenido (es)</FormLabel>
                  <SimpleMDEEditor
                    value={field.value}
                    onChange={(value) => setFieldValue("content.es", value)} // Actualiza el valor usando setFieldValue
                  />
                </FormControl>
              )}
            </Field>
            <Field name="content.en">
              {({ field }) => (
                <FormControl>
                  <FormLabel>Contenido (en)</FormLabel>
                  <SimpleMDEEditor
                    value={field.value}
                    onChange={(value) => setFieldValue("content.en", value)} // Actualiza el valor usando setFieldValue
                  />
                </FormControl>
              )}
            </Field>

            {/* Input para subir una imagen */}
            <Field name="image">
              {({ field }) => (
                <FormControl>
                  <FormLabel>Imagen</FormLabel>
                  <Input
                    type="file"
                    accept="image/*"
                    onChange={(event) => {
                      handleImageChange(event);
                      field.onChange(event); // Actualiza el valor usando field.onChange
                    }}
                  />
                </FormControl>
              )}
            </Field>

            {/* Previsualización de la imagen */}
            {showPreview && previewImage && (
              <Image src={previewImage} alt="Preview" maxH="200px" mt={2} />
            )}

            {/* Resto del código... */}
            <Grid templateColumns="repeat(2, 1fr)" gap={6}>
              <Field name="startDate">
                {({ field }) => (
                  <FormControl>
                    <FormLabel>Fecha de inicio</FormLabel>
                    <DatePicker
                      name="startDate"
                      value={convertTimestampToDate(field.value)}
                      onChange={(date) => setFieldValue("startDate", date)}
                    />
                  </FormControl>
                )}
              </Field>
              <Field name="endDate">
                {({ field }) => (
                  <FormControl>
                    <FormLabel>Fecha de fin</FormLabel>
                    <DatePicker
                      name="endDate"
                      value={convertTimestampToDate(field.value)}
                      onChange={(date) => setFieldValue("endDate", date)}
                    />
                  </FormControl>
                )}
              </Field>
            </Grid>

            <Button
              mt={4}
              colorScheme="teal"
              isLoading={isSubmitting}
              type="submit"
            >
              {isEditing ? "Actualizar" : "Crear"}
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

export default AgendaForm;
