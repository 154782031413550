import { es } from "date-fns/locale";
import { format, formatDistance, parse } from "date-fns";

export const formatDate = (date, formatConstant, locale) => {
  if (typeof date === "string") {
    return format(new Date(date), formatConstant, locale);
  }
  return format(date, formatConstant, locale);
};

export const API_DAY_FORMAT = "yyyy-MM-dd";
export const toApiDayFormat = (date) => formatDate(date, API_DAY_FORMAT);

export const API_DATETIME_FORMAT = "d MMM yyyy - H:mm:ss";
export const toApiDateTimeFormat = (date) =>
  formatDate(date, API_DATETIME_FORMAT, { locale: es });

export const INTERFACE_DAY_FORMAT = "d MMM yyyy";
export const toInterfaceDayFormat = (date) =>
  formatDate(date, INTERFACE_DAY_FORMAT);

export const toInterfaceDayFormatSpanish = (date) =>
  formatDate(date, INTERFACE_DAY_FORMAT, { locale: es });

export const toDistanceFormatSpanish = (date) => {
  return formatDistance(new Date(date), new Date(), {
    addSuffix: true,
    locale: es,
  });
};
export const parseDateToInt = (date) => {
  return parse(date, "y-MM-dd", new Date());
};

export const today = new Date();

export const formatFirestoreDate = (date) => {
  return date.toDate().toLocaleDateString("es-ES");
};
