import { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  useToast,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { doc, getDoc, updateDoc, serverTimestamp } from "firebase/firestore";
import { db } from "config/firebase";
import Sidebar from "components/layouts/Sidebar";
import { useParams } from "react-router-dom";
import AgendaForm from "components/Form/AgendaForm";

const EditPost = () => {
  const { id } = useParams();
  console.log(id);
  const [post, setPost] = useState(null);

  useEffect(() => {
    if (!id) return;

    const fetchPost = async () => {
      const postRef = doc(db, "agenda", id);
      const postDoc = await getDoc(postRef);

      if (postDoc.exists()) {
        setPost({ id: postDoc.id, ...postDoc.data() });
      } else {
        alert("No such post!");
      }
    };

    fetchPost();
  }, [id]);

  console.log(post);

  if (!post) return "Loading...";

  return (
    <Sidebar>
      <Box>{post && <AgendaForm initialValues={post} isEditing={true} />}</Box>
    </Sidebar>
  );
};

export default EditPost;
