import React, { useCallback, memo, useState } from "react";
import { useField, ErrorMessage } from "formik";
import {
  Input as DefaultInput,
  Box,
  useColorModeValue,
} from "@chakra-ui/react";
import ErrorMessageText from "components/ErrorMessageText";

const Input = ({ className, label, value, disabled, isMoney, ...props }) => {
  const [field, meta, { setValue }] = useField(props);
  const hasError = !!(meta.touched && meta.error);
  const inputColor = useColorModeValue("gray.900", "white");
  return (
    <Box>
      <DefaultInput
        {...field}
        {...props}
        id={field.name}
        disabled={disabled}
        size="lg"
        borderColor="gray.300"
        // color={inputColor}
        // bg={disabled ? "gray.100" : "white"}
      />
      {hasError && (
        <ErrorMessage
          component={ErrorMessageText}
          color="red.500"
          fontSize="sm"
          name={field.name}
          mt={1}
        />
      )}
    </Box>
  );
};

export default memo(Input);
