import { useState, useEffect } from "react";
import {
  Box,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  VStack,
  useToast,
  Image,
  Grid,
  Switch,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import {
  collection,
  addDoc,
  serverTimestamp,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db, storage } from "config/firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { Formik, Form, Field } from "formik";
import { v4 as uuidv4 } from "uuid";
import SimpleMDEEditor from "react-simplemde-editor";
import DatePicker from "components/CustomDayPicker";
import slugify from "slugify";
import { fromUnixTime, isValid } from "date-fns";

function AgendaForm({ initialValues, isEditing = false }) {
  const navigate = useNavigate();
  const toast = useToast();
  const [deletedImages, setDeletedImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  useEffect(() => {
    if (
      initialValues &&
      initialValues.images &&
      initialValues.images.length > 0
    ) {
      // Establecer la primera imagen como previewImage
      setPreviewImage(initialValues.images[0]);
    }
  }, [initialValues]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);

      // Preview the image
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (values, actions) => {
    try {
      let imageURL = previewImage;
      if (selectedImage) {
        const storageRef = ref(storage, `images/${uuidv4()}`);
        await uploadBytes(storageRef, selectedImage);
        imageURL = await getDownloadURL(storageRef);
      }

      const postData = {
        title: values.title,
        content: values.content,
        images: imageURL ? [imageURL] : initialValues?.images || [],
        createdAt: isEditing ? values.createdAt : serverTimestamp(),
        startDate: values.startDate,
        endDate: values.endDate,
        slug: isEditing
          ? values.slug
          : `${slugify(values.title.es, { lower: true })}-${uuidv4()}`,
        suitableForDisabled: values.suitableForDisabled,
        suitableForChildren: values.suitableForChildren,
        titleLowerCase: {
          en: values.title.en.toLowerCase(),
          es: values.title.es.toLowerCase(),
        },
      };

      if (isEditing) {
        const postRef = doc(db, "agenda", values.id);
        await updateDoc(postRef, postData);
        toast({
          title: "Evento actualizado",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        await addDoc(collection(db, "agenda"), postData);
        toast({
          title: "Evento creado",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }

      navigate("/agenda");
    } catch (error) {
      console.log(error);
      actions.setStatus(error.message);
      actions.setSubmitting(false);
    }
  };

  const convertTimestampToDate = (timestamp) => {
    if (timestamp && timestamp.seconds) {
      const unixTime = timestamp.seconds;
      return fromUnixTime(unixTime);
    }
    if (isValid(timestamp)) {
      return timestamp;
    }
  };

  return (
    <Box>
      <Heading>{isEditing ? "Editar evento" : "Nuevo evento"}</Heading>
      <Formik
        initialValues={
          initialValues || {
            title: {
              en: "",
              es: "",
            },
            content: {
              en: "",
              es: "",
            },
            images: [],
            startDate: "",
            endDate: "",
            createdAt: serverTimestamp(),
            suitableForDisabled: false,
            suitableForChildren: false,
            slug: "",
          }
        }
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({ values, status, setFieldValue, isSubmitting }) => (
          <Form>
            <Field name="title.es">
              {({ field }) => (
                <FormControl>
                  <FormLabel>Título (es)</FormLabel>
                  <Input
                    {...field}
                    value={field.value}
                    onChange={field.onChange}
                  />
                </FormControl>
              )}
            </Field>
            <Field name="title.en">
              {({ field }) => (
                <FormControl>
                  <FormLabel>Título (en)</FormLabel>
                  <Input
                    {...field}
                    value={field.value}
                    onChange={field.onChange}
                  />
                </FormControl>
              )}
            </Field>

            <Field name="content.es">
              {({ field }) => (
                <FormControl>
                  <FormLabel>Contenido (es)</FormLabel>
                  <SimpleMDEEditor
                    value={field.value}
                    onChange={(value) => setFieldValue("content.es", value)}
                  />
                </FormControl>
              )}
            </Field>
            <Field name="content.en">
              {({ field }) => (
                <FormControl>
                  <FormLabel>Contenido (en)</FormLabel>
                  <SimpleMDEEditor
                    value={field.value}
                    onChange={(value) => setFieldValue("content.en", value)}
                  />
                </FormControl>
              )}
            </Field>

            <Field name="image">
              {({ field }) => (
                <FormControl>
                  <FormLabel>Imagen</FormLabel>
                  <Input
                    type="file"
                    accept="image/*"
                    onChange={(event) => {
                      handleImageChange(event);
                      field.onChange(event);
                    }}
                  />
                </FormControl>
              )}
            </Field>

            {previewImage && (
              <Image src={previewImage} alt="Preview" maxH="200px" mt={2} />
            )}

            <Grid templateColumns="repeat(2, 1fr)" gap={6}>
              <Field name="startDate">
                {({ field }) => (
                  <FormControl>
                    <FormLabel>Fecha de inicio</FormLabel>
                    <DatePicker
                      name="startDate"
                      value={convertTimestampToDate(field.value)}
                      onChange={(date) => setFieldValue("startDate", date)}
                    />
                  </FormControl>
                )}
              </Field>
              <Field name="endDate">
                {({ field }) => (
                  <FormControl>
                    <FormLabel>Fecha de fin</FormLabel>
                    <DatePicker
                      name="endDate"
                      value={convertTimestampToDate(field.value)}
                      onChange={(date) => setFieldValue("endDate", date)}
                    />
                  </FormControl>
                )}
              </Field>
            </Grid>
            <Grid templateColumns="repeat(2, 1fr)" gap={6}>
              <Field name="suitableForDisabled">
                {({ field }) => (
                  <FormControl>
                    <FormLabel>Apto para discapacitados</FormLabel>
                    <Switch
                      {...field}
                      isChecked={field.value}
                      onChange={field.onChange}
                    />
                  </FormControl>
                )}
              </Field>
              <Field name="suitableForChildren">
                {({ field }) => (
                  <FormControl>
                    <FormLabel>Apto para niños</FormLabel>
                    <Switch
                      {...field}
                      isChecked={field.value}
                      onChange={field.onChange}
                    />
                  </FormControl>
                )}
              </Field>
            </Grid>

            <Button
              mt={4}
              colorScheme="teal"
              isLoading={isSubmitting}
              type="submit"
            >
              {isEditing ? "Actualizar" : "Crear"}
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

export default AgendaForm;
